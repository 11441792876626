import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkout"
export default class extends Controller {
  static targets = ['monthly', 'annual'] // not used in markup, just in togglePlanBtn
  static values = { returnUrl: String, couponApplied: String }

  connect() {
    document.querySelector("#payment-form").addEventListener("submit", this.handleSubmit.bind(this));
    this.initializeStripe()
  }

  async initializeStripe() {
    let response = await fetch("/billing_portal.json", {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ coupon: this.couponAppliedValue })
    });

    let { clientSecret, amount } = await response.json();

    this.setupStripeElements(clientSecret)

    // don't show monthly/annual plans if coupon is being applied
    if (!this.couponAppliedValue) {
      setTimeout(function(){plan_options.classList.remove('hidden')}, 700);
    }
    pay_btn_loading.remove();
    this.updatePayBtn(amount);
  }

  setupStripeElements(clientSecret) {
    const appearance = {
      theme: 'night', // https://docs.stripe.com/elements/appearance-api?platform=web#theme
      labels: 'floating', // 'floating' or 'above'

      // https://docs.stripe.com/elements/appearance-api?platform=web#variables
      variables: {
        colorPrimary: '#61c977',
        colorBackground: '#52525b',
        colorText: '#e4e4e7',
        colorDanger: '#ef4444',
        fontFamily: 'DM Sans, sans-serif',
        spacingUnit: '4px',
        borderRadius: '30px',
      },

      // https://docs.stripe.com/elements/appearance-api?platform=web#rules
      rules: {}
    };

    // Pass the appearance + fonts objects to the Elements instance
    let fonts = [{cssSrc: 'https://fonts.googleapis.com/css?family=DM+Sans'}]
    elements = stripe.elements({clientSecret, appearance, fonts: fonts});
    const paymentElement = elements.create("payment");
    paymentElement.mount("#checkout");
  }

  updatePayBtn(amount) {
    let btn = document.getElementById('pay-btn')
    btn.innerText = `Pay $${amount} Now`;
    btn.classList.remove('animate-pulse', 'hidden')
  }

  async switchPlan() {
    let planName = event.currentTarget.dataset.plan; // 'monthly' or 'annual'
    let btn = document.getElementById('pay-btn')

    this.togglePlanBtn(event.currentTarget)

    // animated spinner during price change
    btn.innerHTML = this.spinnerMarkup();

    let response = await fetch("/billing_portal.json", {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ coupon: this.couponAppliedValue, plan: planName })
    });

    let { clientSecret, amount } = await response.json();

    this.setupStripeElements(clientSecret)
    this.updatePayBtn(amount);
  }

  togglePlanBtn(plan) {
    let newPlan = event.currentTarget;
    let oldPlanName = this.constructor.targets.filter(n => ![newPlan.dataset.plan].includes(n))[0]
    let oldPlan = document.querySelector(`[data-plan="${oldPlanName}"]`)

    oldPlan.classList.remove('bg-white', 'border', 'border-white')
    oldPlan.classList.add('bg-zinc-600', 'hover:border-white', 'border', 'border-zinc-600', 'group-hover:text-white')
    oldPlan.getElementsByTagName('p')[0].classList.add('text-zinc-300')
    oldPlan.getElementsByTagName('p')[0].classList.remove('text-black')
    oldPlan.getElementsByTagName('span')[0].classList.add('text-brand-green', 'group-hover:bg-brand-green', 'group-hover:text-white')
    oldPlan.getElementsByTagName('span')[0].classList.remove('bg-brand-green', 'text-white')

    newPlan.classList.add('bg-white', 'border', 'border-white')
    newPlan.classList.remove('bg-zinc-600', 'hover:border-white', 'border', 'border-zinc-600', 'group-hover:bg-brand-green', 'group-hover:text-white')
    newPlan.getElementsByTagName('p')[0].classList.remove('text-zinc-300')
    newPlan.getElementsByTagName('p')[0].classList.add('text-black')
    newPlan.getElementsByTagName('span')[0].classList.remove('text-brand-green', 'group-hover:bg-brand-green', 'group-hover:text-white')
    newPlan.getElementsByTagName('span')[0].classList.add('bg-brand-green', 'text-white')
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: this.returnUrlValue
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      this.showMessage(error.message);
    } else {
      this.showMessage("An unexpected error occurred.");
    }

    this.setLoading(false);
  }

  // Fetches the payment intent status after payment submission
  async checkStatus() {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

    switch (paymentIntent.status) {
      case "succeeded":
        this.showMessage("Payment succeeded!");
        break;
      case "processing":
        this.showMessage("Your payment is processing.");
        break;
      case "requires_payment_method":
        this.showMessage("Your payment was not successful, please try again.");
        break;
      default:
        this.showMessage("Something went wrong.");
        break;
    }
  }

  // ------- UI helpers -------

  showMessage(messageText) {
    const messageContainer = document.querySelector("#payment-message");

    messageContainer.classList.remove("hidden");
    messageContainer.textContent = messageText;

    setTimeout(function () {
      messageContainer.classList.add("hidden");
      messageContainer.textContent = "";
    }, 4000);
  }

  // Show a spinner on payment submission
  setLoading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      document.querySelector("#submit").disabled = true;
      document.querySelector("#spinner").classList.remove("hidden");
      document.querySelector("#spinner").innerHTML = this.spinnerMarkup();
      document.querySelector("#pay-btn").classList.add("hidden");
    } else {
      document.querySelector("#submit").disabled = false;
      document.querySelector("#spinner").classList.add("hidden");
      document.querySelector("#pay-btn").classList.remove("hidden");
    }
  }

  spinnerMarkup() {
    return '<svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-brand-green" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>'
  }
}
