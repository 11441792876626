import { Datepicker } from 'flowbite';
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.bindDatepickers()
  }

  bindDatepickers() {
    [...document.getElementsByClassName('flowbite-datepicker')].forEach(e => new Datepicker(e, {}));
  }
}
