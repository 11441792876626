import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tooltip-accordion"
export default class extends Controller {
  connect() {
    this.bindTooltip()
  }

  // Flowbite JS automatically does this on dom-ready, but not after turbo:load
  // https://github.com/hotwired/turbo/issues/548
  bindTooltip() {
    let targetEl = document.getElementById(this.element.dataset.tooltipTarget)
    let triggerEl = this.element

    // let options = {
    //   placement: 'top',
    //   triggerType: 'hover'
    // };

    new Tooltip(targetEl, triggerEl)
  }
}
