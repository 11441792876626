// inspiration A: https://dev.to/nejremeslnici/how-to-use-view-transitions-in-hotwire-turbo-1kdi
// inspiration B: https://discuss.hotwired.dev/t/are-transitions-and-animations-on-hotwire-roadmap/1547/3
document.addEventListener("turbo:before-stream-render", (event) => {
  // Inspiration A
  if (document.startViewTransition) {
    const originalRender = event.detail.render

    event.detail.render = (currentElement, newElement) => {
      document.startViewTransition(()=> originalRender(currentElement, newElement))
    }
  }

  // Inspiration B
  // let oldElement = document.getElementById(event.target.target)
  // let animation = 'animate__headShake';
  //
  // oldElement.classList.add(animation, 'animate__animated');
  //
  // oldElement.addEventListener('animationend', function() {
  //   oldElement.classList.remove(animation, 'animate__animated');
  // })
})
