import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="historical"
export default class extends Controller {
  submit() {
    this.maintainState(this.element)
    this.element.requestSubmit()
  }

  // recreates turbo's native "turbo_action :advance" functionality which is broken
  // end patch - forces URL to update with included search params for easy copy/paste sharing
  maintainState(form) {
    let formData = new FormData(form)
    let allParams = new URLSearchParams(formData).entries()

    let selectedParams = {}
    for(let [key, value] of allParams) { // each 'entry' is a [key, value] tupple
      selectedParams[key] = value
    }

    let params = Object.fromEntries(
      Object.entries(selectedParams).filter(([key, value]) => value !== '')
    )

    window.history.pushState({}, "", `${window.location.pathname}?${new URLSearchParams(params)}`)
  }
}
