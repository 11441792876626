import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="query-accordion"
export default class extends Controller {
  connect() {
    this.bindAccordion()
  }

  // Flowbite JS automatically does this on dom-ready, but not after turbo:load
  // https://github.com/hotwired/turbo/issues/548
  bindAccordion() {
    let id = this.element.id
    let triggerEl = this.element.querySelector('button')
    let targetEl = document.getElementById(triggerEl.dataset.accordionTarget.substr(1))

    let items = [
      {
          id: id,
          triggerEl: triggerEl,
          targetEl: targetEl,
          active: false
      }
    ]

    let accordion = new Accordion(this.element, items)
    triggerEl.click(); // open accordion by default after querying alerts (accordion.open() doesn't work)
  }
}
