import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  reset() {
    this.element.reset();
  }

  // adds support for familiar "cmd+enter" (or ctrl+enter) shortcut from Slack, Discord etc
  maybeSubmit() {
    if ((event.metaKey || event.ctrlKey) && event.keyCode == 13) {
      this.element.form.requestSubmit();
    }
  }
}
